<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Fatura Taksiti</template>
    <template slot="body">
      <v-form
        ref="form"
        @submit.prevent="handleFormSubmit"
        v-if="totalUninstalled > 0 || id"
      >
        <v-row cols="12" dense>
          <v-col sm="6">
            <rs-datepicker
              :label="$t('labels.date')"
              v-model="formData.last_payment_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>

          <v-col sm="6">
            <rs-money-field
              v-model="formData.amount"
              :rules="
                disabled || amountDisabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, 9999999.99),
                    ]
              "
              :disabled="disabled || amountDisabled"
              :hint="`Taksitlendirilmemiş tutar: ${numberToLocaleFormat(
                totalUninstalled
              )}₺`"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>

      <div v-else-if="!id">
        <v-alert type="error" border="left">
          Faturanın tümü taksitlendirilmiş. Yeni taksit ekleyemezsiniz
        </v-alert>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-submit
          hide-save-close
        />
      </div>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";

export default {
  name: "InvoiceInstallmentForm",
  mixins: [hasForm],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalAmount() {
      if (!this.invoice || !this.invoice.items || !this.invoice.items.length) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.items.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalInstallmentAmount() {
      if (
        !this.invoice ||
        !this.invoice.installments ||
        !this.invoice.installments.length
      ) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.installments.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalUninstalled() {
      const amount =
        (this.totalAmount * 100 - this.totalInstallmentAmount * 100) / 100;
      return amount;
    },
  },
  data() {
    return {
      id: null,
      amountDisabled: false,
    };
  },
  methods: {
    show(id, amount) {
      this.id = null;
      this.amountDisabled = false;
      this.formData = {
        last_payment_on: null,
        amount: amount || null,
      };

      if (amount) {
        this.amountDisabled = true;
      }

      if (id) {
        this.id = id;
        this.load();
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .query(`expense/invoices/${this.invoice.id}/installments/${this.id}`)
        .then((response) => {
          this.loadData(response);
          document.getElementsByClassName("formatted-text")[0].innerHTML =
            this.numberToLocaleFormat(this.formData.amount);
        })
        .catch((error) => this.handleError(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(
            `expense/invoices/${this.invoice.id}/installments/${this.id}`,
            formData
          )
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", this.formData);
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }
            setTimeout(() => this.load(), 100);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`expense/invoices/${this.invoice.id}/installments`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", this.formData));
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }
            this.id = response.data.data.id;
            setTimeout(() => this.load(), 100);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3>
        <v-icon>mdi-newspaper-minus</v-icon>
        Gider Faturası
      </h3>
      <div class="d-block w-100" v-if="$route.params.id">
        <rs-return-to />
      </div>
      <v-col class="text-end" v-if="can('edit-invoice')">
        <v-btn
          class="mx-1"
          color="pink"
          outlined
          small
          :disabled="disabled"
          v-if="id && totalRemaining > 0"
          @click="handleInvoiceCloseClick"
        >
          Fatura Kapat
        </v-btn>
        <v-btn
          class="mx-1"
          color="pink"
          outlined
          small
          v-if="id && $refs.invoiceReceiptForm && can('print')"
          @click="$refs.invoiceReceiptForm.show(id)"
        >
          Mahsup Fişi Yazdır
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <rs-action
          class="mx-1"
          :disabled="disabled"
          v-if="id"
          :to="{
            name: 'assessments.parametric.create',
            query: {
              invoice_id: id,
            },
          }"
          title="Tahakkuk Oluştur"
        >
          Tahakkuk Oluştur
          <v-icon>mdi-variable</v-icon>
        </rs-action>
        <v-btn
          class="me-2"
          color="pink"
          outlined
          @click="showActionLogs"
          v-if="id"
          icon
          small
        >
          İ
        </v-btn>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="handleFormSubmit" v-if="clusterId">
        <v-row dense cols="12">
          <v-col sm="8">
            <v-row dense cols="12">
              <v-col sm="6">
                <rs-select-provider
                  v-model="formData.provider_id"
                  :rules="disabled ? [] : [rules.required]"
                  :filled="disabled"
                  :readonly="disabled"
                />
              </v-col>
              <v-col sm="6">
                <rs-select-project
                  v-model="formData.project_id"
                  :filled="disabled"
                  :readonly="disabled"
                />
              </v-col>
              <v-col sm="6">
                <rs-datepicker
                  label="Fatura Tarihi"
                  v-model="formData.billed_on"
                  :rules="
                    disabled || formData.invoiced_type_id === 5
                      ? []
                      : [rules.required]
                  "
                  :disabled="disabled || formData.invoiced_type_id === 5"
                />
              </v-col>
              <v-col sm="6">
                <rs-datepicker
                  label="Son Ödeme Tarihi"
                  v-model="formData.last_payment_on"
                  :rules="disabled ? [] : [rules.required]"
                  :disabled="disabled"
                />
              </v-col>
              <v-col sm="4">
                <rs-select
                  label="Fatura Tipi"
                  v-model="formData.invoiced_type_id"
                  :rules="disabled ? [] : [rules.required]"
                  :items="invoiceTypeList"
                  :filled="disabled"
                  :readonly="disabled"
                  @change="handleInvoicedTypeIdChange"
                />
              </v-col>
              <v-col sm="4">
                <rs-text-field
                  label="Belge No(Fatura-Fiş No)"
                  v-model="formData.invoice_no"
                  :rules="[
                    formData.invoiced_type_id === 2 ? rules.required : true,
                    rules.maxLength(formData.invoice_no, 50),
                  ]"
                  maxlength="50"
                  :filled="disabled"
                  :readonly="disabled"
                >
                  <template v-slot:append v-if="duplicateInvoiceNo">
                    <strong>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="orange" dark v-bind="attrs" v-on="on">
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        Bu numaraya ait bir fatura girilmiş.
                      </v-tooltip>
                    </strong>
                  </template>
                </rs-text-field>
              </v-col>
              <v-col sm="4">
                <rs-text-field
                  label="Tesisat No"
                  v-model="formData.utility_no"
                  :rules="[rules.maxLength(formData.utility_no, 50)]"
                  maxlength="50"
                  :filled="disabled"
                  :readonly="disabled"
                />
              </v-col>
              <v-col sm="12" class="mb-3">
                <rs-textarea
                  label="Açıklama"
                  v-model="formData.description"
                  :filled="disabled"
                  :readonly="disabled"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="1" class="text-center"><v-divider vertical /></v-col>
          <v-col sm="3">
            <v-row dense>
              <v-col sm="12">
                <rs-money-field
                  :value="totalAmount"
                  label="Fatura Tutarı"
                  :disabled="true"
                />
              </v-col>
              <v-col sm="12" v-if="id">
                <rs-money-field
                  :value="totalPayment"
                  label="Ödenen Tutar"
                  :disabled="true"
                />
              </v-col>
              <v-col sm="12" v-if="id">
                <rs-money-field
                  :value="totalRemaining"
                  label="Kalan Tutar"
                  :disabled="true"
                />
              </v-col>
              <v-col sm="12" v-if="id">
                <rs-text-field
                  :value="originalData.document_no"
                  label="Evrak No"
                  filled
                  disabled
                  hint="TYA bazında benzersizdir"
                />
              </v-col>
              <v-col sm="12" v-if="id">
                <rs-text-field
                  :value="
                    new Date(originalData.created_at).toLocaleDateString()
                  "
                  label="Kayıt Tarihi"
                  filled
                  disabled
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-tabs v-model="selectedTab">
          <v-tab to="#items" replace>
            <v-badge
              color="deep-purple lighten-3"
              :content="items.length || '0'"
            >
              Fatura Kalemleri
            </v-badge>
          </v-tab>
          <v-tab to="#installments" replace :disabled="!id">
            <v-badge
              color="deep-purple lighten-3"
              :content="installments.length || '0'"
            >
              Taksitler
            </v-badge>
          </v-tab>
          <v-tab to="#payments" replace :disabled="!id">
            <v-badge
              color="deep-purple lighten-3"
              :content="payments.length || '0'"
            >
              Ödemeler
            </v-badge>
          </v-tab>
          <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
            <v-badge color="deep-purple lighten-3" :content="noteCount || '0'">
              {{ $t("headers.notes_and_reminders") }}
            </v-badge>
          </v-tab>
          <v-tab
            to="#documents"
            replace
            :disabled="!id"
            v-if="can('see-document')"
          >
            <v-badge
              color="deep-purple lighten-3"
              :content="documentCount || '0'"
            >
              {{ $t("headers.documents") }}
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item value="items">
            <div class="py-5">
              <InvoiceItems
                :allow-add="disabled ? false : true"
                v-model="items"
                :invoice="formData"
                :hide-delete="payments.length > 0"
                :amount-disabled="payments.length > 0"
                :has-installments="installments.length"
              />
            </div>
          </v-tab-item>
          <v-tab-item value="installments">
            <div class="py-5" v-if="id">
              <template v-if="!activateInstallments">
                <p>
                  Faturayı taksitli ödemeye çevirmek için aşağıdaki tuşu
                  kullanın.
                </p>

                <v-btn
                  color="pink"
                  @click="activateInstallments = true"
                  outlined
                  :disabled="disabled || !can('edit-invoice')"
                >
                  Taksitli Faturaya Çevir
                </v-btn>
              </template>

              <InvoiceInstallments
                :allow-add="disabled || !can('edit-invoice') ? false : true"
                v-if="activateInstallments"
                v-model="installments"
                :invoice="originalData"
                @updated="load()"
              />
            </div>
          </v-tab-item>
          <v-tab-item value="payments">
            <div class="py-5" v-if="id">
              <InvoicePayments
                :allow-add="disabled ? false : true"
                v-model="payments"
                :invoice="originalData"
                @updated="load()"
              />
            </div>
          </v-tab-item>
          <v-tab-item value="notes" class="pa-4" :eager="id">
            <NoteList
              :allow-add="disabled ? false : true"
              :extra-params="extraParams"
              :item-count.sync="noteCount"
            />
          </v-tab-item>
          <v-tab-item value="documents" class="pa-4" :eager="id">
            <DocumentList
              :allow-add="disabled ? false : true"
              :extra-params="extraParams"
              :item-count.sync="documentCount"
            />
          </v-tab-item>
        </v-tabs-items>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="handleCancelClick"
          @submit="handleFormSubmit"
          v-if="!disabled"
        />
      </v-form>
      <p v-else>Lütfen üst menüden bir toplu yaşam alanı seçin.</p>
    </v-card-text>

    <InvoicePaymentForm
      ref="invoicePaymentForm"
      :invoice="originalData"
      @saved="load"
    />
    <ActionLogList ref="actionLogList" />
    <InvoiceReceiptForm ref="invoiceReceiptForm" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { hasForm, hasPermissions } from "@/view/mixins";
import DocumentList from "@/view/content/documents/DocumentList";
import NoteList from "@/view/content/Notes/NoteList";
import InvoiceInstallments from "./InvoiceInstallments";
import InvoiceItems from "./InvoiceItems";
import InvoicePayments from "./InvoicePayments";
import InvoicePaymentForm from "../forms/InvoicePaymentForm";
import InvoiceReceiptForm from "../forms/InvoiceReceiptForm";

export default {
  name: "InvoiceForm",
  mixins: [hasForm, hasPermissions],
  components: {
    ActionLogList,
    DocumentList,
    InvoiceInstallments,
    InvoiceItems,
    InvoicePayments,
    InvoicePaymentForm,
    InvoiceReceiptForm,
    NoteList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: "simple",
    },
  },
  computed: {
    ...mapGetters(["cluster", "clusterId", "invoiceTypeList"]),
    totalAmount() {
      if (!this.items.length) {
        return 0;
      }

      let totalAmount = 0;

      this.items.forEach((item) => (totalAmount += parseFloat(item.amount)));

      return totalAmount;
    },
    totalPayment() {
      if (!this.payments.length) {
        return 0;
      }

      let totalAmount = 0;

      this.payments.forEach((item) => (totalAmount += parseFloat(item.amount)));

      return totalAmount;
    },
    totalRemaining() {
      const amount = (this.totalAmount * 100 - this.totalPayment * 100) / 100;

      return amount;
    },
    extraParams() {
      return { invoice_id: this.id };
    },
  },
  watch: {
    id() {
      this.load();
    },
    "formData.invoice_no": {
      handler: debounce(function () {
        this.handleInvoiceNoChanged();
      }),
    },
  },
  mounted() {
    if (this.$router.currentRoute.query.provider_id) {
      this.formData.provider_id = parseInt(
        this.$router.currentRoute.query.provider_id
      );
    }
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
  data() {
    return {
      selectedTab: null,
      formData: {
        provider_id: null,
        billed_on: new Date().toJSON().split("T")[0],
        last_payment_on: new Date().toJSON().split("T")[0],
        invoiced_type_id: null,
        invoice_no: null,
        utility_no: null,
        project_id: null,
        description: null,
      },
      items: [],
      installments: [],
      payments: [],
      documentCount: null,
      noteCount: null,
      activateInstallments: false,
      duplicateInvoiceNo: false,
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (!this.items.length) {
        this.$toast.error("En az bir tane fatura kalemi girmelisiniz.");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);
      formData.items = [].concat(this.items);

      if (!this.id) {
        formData.cluster_id = this.clusterId;
      }

      if (this.id) {
        this.$api
          .put(`expense/invoices/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", this.formData);
            if (event && event.closeOnSave) {
              this.$router.push({
                name: "expenses.invoices.list",
                query: {
                  order_by: "billed_on",
                  order: "asc",
                },
              });
              return;
            }

            this.loadData(response);

            this.items = response.data.data.items;
            this.payments = response.data.data.payments;
            this.installments = response.data.data.installments;
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("expense/invoices", formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", this.formData));
            if (event && event.closeOnSave) {
              this.$router.push({
                name: "expenses.invoices.list",
                query: {
                  order_by: "billed_on",
                  order: "asc",
                },
              });
              return;
            }

            this.$router.replace({
              name: "expenses.invoices.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    load() {
      if (!this.id) {
        this.formData = {
          provider_id: null,
          billed_on: new Date().toJSON().split("T")[0],
          last_payment_on: new Date().toJSON().split("T")[0],
          invoiced_type_id: null,
          invoice_no: null,
          utility_no: null,
          project_id: null,
          description: null,
        };
        this.items = [];
        this.installments = [];
        this.payments = [];
        this.documentCount = null;
        this.noteCount = null;
        this.selectedTab = "items";

        return;
      }

      this.isLoading = true;

      return this.$api
        .query(`expense/invoices/${this.id}`)
        .then((response) => {
          this.loadData(response);

          this.items = response.data.data.items || [];
          this.payments = response.data.data.payments || [];
          this.installments = response.data.data.installments || [];

          if (this.installments.length) {
            this.activateInstallments = true;
          }

          this.updateClusterId(this.originalData.cluster_id);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleInvoiceCloseClick() {
      this.$refs.invoicePaymentForm.show(null, this.totalRemaining);
    },
    handleInvoicedTypeIdChange(newValue) {
      if (newValue === 5 && this.cluster.legal_beginning_date) {
        const split = this.cluster.legal_beginning_date.split("-");
        const beginningDate = new Date(this.cluster.legal_beginning_date)
          ? new Date(Date.UTC(split[0], parseInt(split[1]) - 1, split[2]))
          : null;

        if (!beginningDate) {
          return null;
        }
        this.formData.billed_on = new Date(
          Date.UTC(
            beginningDate.getUTCFullYear(),
            beginningDate.getUTCMonth(),
            beginningDate.getUTCDate() - 1
          )
        )
          .toJSON()
          .split("T")[0];
      }
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Expense\\Invoice", this.id);
    },
    handleInvoiceNoChanged() {
      const params = {
        cluster: [this.clusterId],
        invoice_no: this.formData.invoice_no,
      };

      this.duplicateInvoiceNo = false;

      this.$api.query("expense/invoices", { params }).then((response) => {
        for (const index in response.data.data) {
          const item = response.data.data[index];

          if (item.invoice_no === this.formData.invoice_no) {
            if (item.id !== this.id) {
              this.duplicateInvoiceNo = true;
            }
          }
        }
      });
    },
  },
};
</script>

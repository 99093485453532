<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Gider Tipi</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="12">
            <rs-text-field
              label="Adı"
              :rules="[rules.required, rules.maxLength(formData.name, 100)]"
              v-model="formData.name"
              :readonly="disabled || (id && !originalData.cluster_id)"
              :filled="disabled || (id && !originalData.cluster_id)"
            />
          </v-col>

          <v-col sm="12">
            <rs-select
              :items="expenseTypeList"
              label="Gider Türü"
              :rules="[rules.required]"
              v-model="formData.parent_id"
              :readonly="disabled"
              :filled="disabled"
              :disabled="parentIdDisabled"
              required
            />
          </v-col>

          <!-- <v-col sm="12">
            <rs-bank-keywords
              v-model="formData.bank_keywords"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col> -->
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";
import ClusterService from "@/core/services/cluster.service";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId", "expenseTypeList"]),
  },
  data() {
    return {
      invoiceList: null,
      id: null,
      parentIdDisabled: false,
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(id, parentId) {
      this.id = id || null;

      this.parentIdDisabled = false;

      if (parentId) {
        this.parentIdDisabled = true;
      }

      this.formData = {
        cluster_id: null,
        name: null,
        // bank_keywords: [],
        parent_id: parentId || null,
      };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();

        if (this.id) {
          this.load();
        }
      });
    },
    hide() {
      this.invoiceList = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      this.isLoading = true;

      return this.$api
        .query(`types/expense-types/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (
            this.originalData.cluster_id &&
            this.clusterId !== this.originalData.cluster_id
          ) {
            this.updateClusterId(this.originalData.cluster_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      if (!this.id) {
        formData.cluster_id = this.clusterId;
      }

      if (this.id) {
        this.$api
          .put(`types/expense-types/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", response.data.data);
            ClusterService.loadExpenseTypeList();
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("types/expense-types", formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", response.data.data));
            ClusterService.loadExpenseTypeList();
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<template>
  <v-card>
    <v-card-text v-if="invoice">
      <v-data-table
        :fixed-header="false"
        :height="undefined"
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        hide-default-footer
        disable-sort
        disable-filtering
        disable-pagination
        :items-per-page="-1"
      >
        <template v-slot:top>
          <rs-table-title-bar
            :add-route="totalRemaining > 0 && allowAdd ? '#' : null"
            @click:add="handleAddClick"
            @reload="loadList"
            hide-edit
            hide-filter-clear
            :show-reload="false"
            icon=""
            :show-delete="allowAdd"
            :delete-enabled="selectedItems.length > 0"
            @click:delete="handleDeleteClick"
            hide-search
            hide-import
          >
            <template slot="buttons:prepend">
              <v-btn
                class="mx-1"
                color="pink"
                outlined
                small
                v-if="selectedItems.length === 1 && can('print')"
                @click="
                  $refs.invoicePaymentReceiptForm.show(selectedItems[0].id)
                "
              >
                Ödeme Makbuzu Yazdır
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string :limit="20" :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.collected_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.payed_to="{ item }">
          <span v-if="item.bank_account_id">{{ item.bank_account.name }}</span>
          <span v-else-if="item.safe_id">{{ item.safe.name }}</span>
        </template>
      </v-data-table>

      <InvoicePaymentForm
        @saved="handleItemSaved"
        ref="invoicePaymentForm"
        :invoice="invoice"
      />

      <InvoicePaymentReceiptForm ref="invoicePaymentReceiptForm" />
    </v-card-text>
  </v-card>
</template>

<script>
import { hasDataTable, hasPermissions } from "@/view/mixins";
import InvoicePaymentForm from "../forms/InvoicePaymentForm";
import InvoicePaymentReceiptForm from "../forms/InvoicePaymentReceiptForm";

export default {
  name: "InvoicePayments",
  mixins: [hasDataTable, hasPermissions],
  components: {
    InvoicePaymentForm,
    InvoicePaymentReceiptForm,
  },
  props: {
    allowAdd: {
      default: true,
      required: false,
      type: Boolean,
    },
    value: {
      type: Array,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
  },
  watch: {
    invoice: {
      handler() {
        this.loadList();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    totalAmount() {
      if (!this.invoice || !this.invoice.items || !this.invoice.items.length) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.items.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalPayment() {
      if (
        !this.invoice ||
        !this.invoice.payments ||
        !this.invoice.payments.length
      ) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.payments.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalRemaining() {
      const amount = (this.totalAmount * 100 - this.totalPayment * 100) / 100;

      return amount;
    },
  },
  data() {
    return {
      list: [],
      headers: [
        {
          text: "Ödeme Tarihi",
          value: "collected_on",
          searchable: false,
          sortable: false,
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: false,
          sortable: false,
          align: "end",
        },
        {
          text: "Banka / Kasa",
          value: "payed_to",
          searchable: false,
          sortable: false,
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: false,
          sortable: false,
        },
      ],
    };
  },
  methods: {
    loadList() {
      this.list = [].concat(this.value);

      const list = [].concat(this.value);

      const footTotals = { amount: 0 };

      for (const index in list) {
        footTotals.amount += list[index].amount * 100;
      }

      footTotals.amount = footTotals.amount / 100;
      this.footTotals = footTotals;

      // if (this.isLoading || !this.invoice || !this.invoice.id) {
      //   return false;
      // }
      //
      // this.isLoading = true;
      // this.list = [];
      // this.footTotals = [];
      // this.selectedItems = [];
      //
      // this.$api
      //   .query(`expense/invoices/${this.invoice.id}/payments`)
      //   .then((response) => {
      //     const list = [];
      //
      //     if (response.data && response.data.data) {
      //       for (const item of response.data.data) {
      //         list.push(item);
      //       }
      //     }
      //
      //     this.list = list;
      //
      //     if (response.data && response.data.meta) {
      //       this.total = response.data.meta.total;
      //       this.options.page = response.data.meta.current_page;
      //       this.footTotals = response.data.meta.totals || {};
      //     }
      //   })
      //   .catch(this.handleError)
      //   .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.invoicePaymentForm.show();
    },
    handleItemSaved() {
      this.$nextTick(() => this.$emit("updated"));
    },
    handleDeleteClick() {
      if (!this.selectedItems.length) {
        return false;
      }

      const params = {
        ids: this.selectedItems.map((item) => item.id),
      };

      this.isLoading = true;

      this.$api
        .delete("expense/payments", params)
        .then(() => {
          this.$toast.success("Seçilen fatura ödemeleri silindi");
          this.$emit("updated");
          this.selectedItems = [];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deletePayment(paymentId) {
      return this.$api
        .delete(`expense/payments/${paymentId}`)
        .then(() => {
          this.$toast.success("Ödeme silindi");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

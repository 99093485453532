<template>
  <v-card>
    <v-card-text v-if="invoice">
      <v-data-table
        :fixed-header="false"
        :height="undefined"
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        hide-default-footer
        disable-sort
        disable-filtering
        disable-pagination
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-alert
            color="error darken-1"
            border="left"
            dark
            v-if="
              totalAmount &&
              totalInstallmentAmount &&
              totalInstallmentAmount !== totalAmount
            "
          >
            <!-- added random comment by ege 2022-06-14 13:21:00 -->
            Taksitlerin toplamı, fatura tutarını karşılamıyor.<br />
            Toplam tutar: {{ numberToLocaleFormat(totalAmount) }}₺ Taksit
            toplamı: {{ numberToLocaleFormat(totalInstallmentAmount) }}₺
          </v-alert>

          <v-alert
            color="info darken-1"
            border="left"
            dark
            v-else-if="totalAmount && !totalInstallmentAmount"
          >
            Bu fatura şu an <strong>tek ödeme</strong> türündedir.
            Taksitlendirmek için aşağıdaki tabloyu kullanabilirsiniz.
          </v-alert>

          <rs-table-title-bar
            :add-route="totalRemaining > 0 && allowAdd ? '#' : null"
            @click:add="handleAddClick"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1 && allowAdd"
            hide-edit
            @click:edit="handleEditClick"
            hide-filter-clear
            :show-reload="false"
            icon=""
            :show-delete="allowAdd"
            :delete-enabled="selectedItems.length > 0"
            @click:delete="handleDeleteClick"
            hide-search
            hide-import
          >
            <template slot="buttons:prepend">
              <v-btn
                small
                color="pink"
                title="Otomatik taksitlendir"
                class="float-end mx-1"
                @click.prevent="handleAutoInstallmentClick"
                outlined
                :loading="isLoading"
                v-if="allowAdd"
              >
                Otomatik Taksitlendir
              </v-btn>

              <v-btn
                small
                color="pink"
                title="Otomatik taksitlendir"
                class="float-end mx-1"
                @click.prevent="handleDeleteAllClick"
                outlined
                v-if="list.length > 0 && allowAdd"
                :loading="isLoading"
              >
                Tüm Taksitleri Sil
              </v-btn>
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.installment_no="{ index }">
          <rs-table-cell-number :value="index + 1" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_collected="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_remaining="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string :limit="20" :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.last_payment_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
      </v-data-table>

      <InvoiceInstallmentForm
        @saved="handleItemSaved"
        ref="installmentForm"
        :invoice="invoice"
      />

      <InvoiceAutoInstallmentForm
        @saved="handleItemSaved"
        ref="autoInstallmentForm"
        :invoice="invoice"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import hasDataTable from "@/view/mixins/hasDataTable";
import InvoiceAutoInstallmentForm from "../forms/InvoiceAutoInstallmentForm";
import InvoiceInstallmentForm from "../forms/InvoiceInstallmentForm";

export default {
  name: "InvoiceInstallments",
  mixins: [hasDataTable],
  components: {
    InvoiceAutoInstallmentForm,
    InvoiceInstallmentForm,
  },
  props: {
    allowAdd: {
      default: true,
      required: false,
      type: Boolean,
    },
    value: {
      type: Array,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
  },
  watch: {
    invoice: {
      handler() {
        this.loadList();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    totalAmount() {
      if (!this.invoice || !this.invoice.items || !this.invoice.items.length) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.items.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalPayment() {
      if (
        !this.invoice ||
        !this.invoice.payments ||
        !this.invoice.payments.length
      ) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.payments.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalRemaining() {
      const amount = (this.totalAmount * 100 - this.totalPayment * 100) / 100;

      return amount;
    },
    totalInstallmentAmount() {
      if (
        !this.invoice ||
        !this.invoice.installments ||
        !this.invoice.installments.length
      ) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.installments.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalUninstalled() {
      const amount =
        (this.totalAmount * 100 - this.totalInstallmentAmount * 100) / 100;
      return amount;
    },
  },
  data() {
    return {
      list: [],
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Taksit No",
          value: "installment_no",
          searchable: false,
          sortable: false,
          align: "end",
        },
        {
          text: "Taksit Tarihi",
          value: "last_payment_on",
          searchable: false,
          sortable: false,
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: false,
          sortable: false,
          align: "end",
        },
        {
          text: "Ödenen Tutar",
          value: "amount_collected",
          searchable: false,
          sortable: false,
          align: "end",
        },
        {
          text: "Kalan Tutar",
          value: "amount_remaining",
          searchable: false,
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  methods: {
    loadList() {
      let paymentAmount = this.totalPayment;
      const list = [].concat(this.value);

      const footTotals = {
        amount: 0,
        amount_collected: 0,
        amount_remaining: 0,
      };

      for (const index in list) {
        if (paymentAmount > 0) {
          if (paymentAmount > list[index].amount) {
            list[index].amount_collected = list[index].amount;
            list[index].amount_remaining = 0.0;
            paymentAmount -= list[index].amount;
          } else {
            list[index].amount_collected = paymentAmount;
            list[index].amount_remaining = list[index].amount - paymentAmount;
            paymentAmount = 0;
          }
        } else {
          list[index].amount_collected = 0.0;
          list[index].amount_remaining = list[index].amount;
        }

        footTotals.amount += list[index].amount * 100;
        footTotals.amount_collected += list[index].amount_collected * 100;
        footTotals.amount_remaining += list[index].amount_remaining * 100;
      }

      footTotals.amount = footTotals.amount / 100;
      footTotals.amount_collected = footTotals.amount_collected / 100;
      footTotals.amount_remaining = footTotals.amount_remaining / 100;

      this.list = list;
      this.footTotals = footTotals;
      // if (this.isLoading || !this.invoice || !this.invoice.id) {
      //   return false;
      // }
      //
      // this.isLoading = true;
      // this.list = [];
      // this.footTotals = [];
      // this.selectedItems = [];
      //
      // this.$api
      //   .query(`expense/invoices/${this.invoice.id}/installments`)
      //   .then((response) => {
      //     const list = [];
      //
      //     if (response.data && response.data.data) {
      //       for (const item of response.data.data) {
      //         list.push(item);
      //       }
      //     }
      //
      //     this.list = list;
      //
      //     if (response.data && response.data.meta) {
      //       this.total = response.data.meta.total;
      //       this.options.page = response.data.meta.current_page;
      //       this.footTotals = response.data.meta.totals || {};
      //     }
      //   })
      //   .catch(this.handleError)
      //   .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.installmentForm.show();
    },
    handleItemSaved() {
      this.$nextTick(() => this.$emit("updated"));
    },
    handleDeleteClick() {
      if (!this.selectedItems.length) {
        return false;
      }

      const selectedItemIds = this.selectedItems.map((item) => item.id);
      const promises = [];

      this.isLoading = true;

      for (const index in selectedItemIds) {
        const promise = this.deleteInstallment(selectedItemIds[index]);
        promises.push(promise);
      }

      Promise.all(promises).finally(() => {
        this.isLoading = false;
        this.$nextTick(() => this.$emit("updated"));
      });
    },
    deleteInstallment(installmentId) {
      return this.$api
        .delete(
          `expense/invoices/${this.invoice.id}/installments/${installmentId}`
        )
        .then(() => {
          // this.$toast.success("Taksit silindi");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$refs.installmentForm.show(this.selectedItems[0].id);
      }
    },
    handleAutoInstallmentClick() {
      if (this.invoice.payments.length) {
        this.$toast.error(
          "Ödeme yapılmış faturaya taksitlendirme yapamazsınız."
        );
        return false;
      }

      if (this.totalInstallmentAmount >= this.totalAmount) {
        this.$toast.error(
          "Faturanın tüm bedeli taksitlendirilmiş. Yenisini ekleyemezsiniz."
        );
        return false;
      }

      this.$refs.autoInstallmentForm.show();
    },
    handleDeleteAllClick() {
      const selectedItemIds = this.list.map((item) => item.id);
      const promises = [];

      this.isLoading = true;

      for (const index in selectedItemIds) {
        const promise = this.deleteInstallment(selectedItemIds[index]);
        promises.push(promise);
      }

      Promise.all(promises).finally(() => {
        this.isLoading = false;
        this.$toast.success("Tüm taksitler silindi.");
        this.$nextTick(() => this.$emit("updated"));
      });
    },
  },
};
</script>

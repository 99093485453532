<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Mahsup Fişi</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <!-- <v-col sm="12">
            <v-radio-group
              row
              mandatory
              v-model="formData.usage_type_id"
              @change="handleUsageTypeChange"
            >
              <v-radio
                value="email-resident"
                label="TYA Sakinine E-Posta Gönder"
              />
              <v-radio
                value="email-provider"
                label="Tedarikçiye E-Posta Gönder"
              />
              <v-radio value="email-any" label="E-Posta Adresine Gönder" />
              <v-radio value="download" label="Yazdır" />
            </v-radio-group>
          </v-col> -->

          <v-col sm="12">
            <rs-select
              label="Dosya Tipi"
              v-model="formData.file_type"
              :items="fileTypeList"
              :rules="[rules.required]"
              required
            />
          </v-col>

          <!-- <v-col sm="6" v-if="formData.usage_type_id === 'email-resident'">
            <rs-select-resident
              label="Paylaşılan Sakin"
              v-model="formData.email_resident_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              required
            />
          </v-col>

          <v-col sm="6" v-if="formData.usage_type_id === 'email-provider'">
            <rs-select-provider
              label="Paylaşılan Tedarikçi"
              v-model="formData.email_provider_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              required
            />
          </v-col>

          <v-col cols="12" v-if="formData.usage_type_id === 'email-any'">
            <rs-text-field
              type="email"
              :label="$t('labels.email')"
              v-model="formData.email"
              :rules="[rules.required, rules.email]"
              required
            />
          </v-col> -->

          <v-col cols="6" v-if="formData.usage_type_id === 'download'">
            <rs-select
              label="Sayfa Tipi"
              v-model="formData.paper_size"
              :items="paperSizeList"
              :rules="[rules.required]"
              required
            />
          </v-col>

          <v-col cols="6" v-if="formData.usage_type_id === 'download'">
            <rs-text-field
              type="number"
              min="1"
              step="1"
              max="100"
              label="Kopya Sayısı"
              v-model="formData.copies"
              :rules="[
                rules.required,
                rules.min(formData.copies, 1),
                rules.max(formData.copies, 100),
              ]"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-save-close
          save-label="İndir"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  name: "InvoiceReceiptForm",
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["cluster", "clusterId"]),
  },
  data() {
    return {
      isLoading: false,
      formData: {},
      receiptData: {},
      invoicePaymentId: null,
      fileTypeList: [
        {
          id: "odt",
          name: "ODT (LibreOffice)",
        },
        {
          id: "docx",
          name: "DOCX (Microsoft Word)",
        },
        {
          id: "pdf",
          name: "PDF (Adobe PDF)",
        },
        {
          id: "png",
          name: "PNG (Görsel)",
        },
        {
          id: "xlsx",
          name: "XLSX (Microsoft Excel)",
        },
        {
          id: "ods",
          name: "ODS (LibreOffice)",
        },
      ],
      paperSizeList: [
        {
          id: "A4",
          name: "A4",
        },
        {
          id: "A5",
          name: "A5",
        },
      ],
      print_template: null,
    };
  },
  methods: {
    show(invoicePaymentId) {
      this.invoicePaymentId = invoicePaymentId;

      this.formData = {
        usage_type_id: "download",
        // email: null,
        // email_resident_id: null,
        file_type: "pdf",
        paper_size: "A5",
        copies: 2,
      };

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    // handleUsageTypeChange() {
    //   if (this.formData.usage_type_id !== "email-resident") {
    //     this.formData.email_resident_id = null;
    //   }
    // },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }
      this.isLoading = true;

      const params = Object.assign({}, this.formData);

      return this.$api
        .query(`expense/invoices/${this.invoicePaymentId}/receipt-data`, {
          params,
        })
        .then((response) => {
          this.receiptData = Object.assign({}, response.data.data);
        })
        .then(() => {
          const params = {
            file_type: this.formData.file_type,
          };
          params.print_template = "Invoice";
          params.receiptData = this.receiptData;

          params.receiptData.billed_on = new Date(
            params.receiptData.billed_on
          ).toLocaleDateString();

          // if (this.formData.usage_type_id === "email-resident") {
          //   params.email_resident_id = this.formData.email_resident_id;
          // } else if (this.formData.usage_type_id === "email-provider") {
          //   params.email_provider_id = this.formData.email_provider_id;
          // } else if (this.formData.usage_type_id === "email-any") {
          //   params.email = this.formData.email;
          //   params.cluster_id = this.clusterId;
          // } else if (this.formData.usage_type_id === "download") {
          //   params.paper_size = this.formData.paper_size;
          //   params.copies = this.formData.copies;
          // }

          params.paper_size = this.formData.paper_size;
          params.copies = this.formData.copies;

          return this.$api.post("print-file", params).then((response) => {
            if (this.formData.usage_type_id === "download") {
              if (response.data.data.url) {
                const a = document.createElement("a");
                a.href = response.data.data.url;
                a.setAttribute("target", "_blank");
                a.click();
                if (event && event.closeOnSave) {
                  this.hide();
                }
              } else {
                this.$toast.error("Mahsup fişi üretilemedi.");
              }
            } else {
              this.$toast.success("Gönderildi");
            }

            this.hide();
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

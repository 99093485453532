<template>
  <v-card>
    <v-card-text v-if="invoice">
      <v-data-table
        :fixed-header="false"
        :height="undefined"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        hide-default-footer
        item-key="uniqueKey"
        disable-sort
        disable-filtering
        disable-pagination
        :items-per-page="-1"
      >
        <template v-slot:top>
          <rs-table-title-bar
            :add-route="allowAdd ? '#' : null"
            @click:add="handleAddClick"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1 && allowAdd"
            :hide-edit="!can('edit-invoice')"
            @click:edit="handleEditClick"
            hide-filter-clear
            :show-reload="false"
            icon=""
            :show-delete="!hideDelete && can('delete-invoice')"
            :delete-enabled="selectedItems.length > 0 && allowAdd"
            @click:delete="handleDeleteClick"
            hide-search
            hide-import
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }" v-if="allowAdd">
          <v-btn text icon @click="handleEditClick(item)">
            <i class="menu-icon mdi mdi-pencil"></i>
          </v-btn>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string :limit="20" :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.expense_type="{ item }">
          {{
            getExpenseType(item.expense_type_id) &&
            getExpenseType(item.expense_type_id).name
          }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.child_expense_type="{ item }">
          {{
            getChildExpenseType(
              item.expense_type_id,
              item.child_expense_type_id
            ) &&
            getChildExpenseType(
              item.expense_type_id,
              item.child_expense_type_id
            ).name
          }}
        </template>
      </v-data-table>

      <InvoiceItemForm
        @saved="handleItemSaved"
        ref="invoiceItemForm"
        :amount-disabled="amountDisabled"
        :has-installments="hasInstallments"
      />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import ClusterService from "@/core/services/cluster.service";
import { hasDataTable, hasPermissions } from "@/view/mixins";
import InvoiceItemForm from "../forms/InvoiceItemForm";

export default {
  name: "InvoiceItems",
  mixins: [hasDataTable, hasPermissions],
  components: {
    InvoiceItemForm,
  },
  props: {
    allowAdd: {
      default: true,
      required: false,
      type: Boolean,
    },
    value: {
      type: Array,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
    hideDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    amountDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasInstallments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["expenseTypeList"]),
  },
  watch: {
    value: {
      handler(n, o) {
        if (JSON.stringify(n) !== JSON.stringify(o)) {
          this.loadList();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      list: [],
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-invoice"),
        },
        {
          text: "Gider Türü",
          value: "expense_type",
          searchable: false,
          sortable: false,
        },
        {
          text: "Gider Tipi",
          value: "child_expense_type",
          searchable: false,
          sortable: false,
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: false,
          sortable: false,
          align: "end",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: false,
          sortable: false,
        },
      ],
    };
  },
  methods: {
    loadList() {
      const list = [];
      this.list = [];
      this.selectedItems = [];
      const footTotals = { amount: 0 };

      for (const index in this.value) {
        footTotals.amount += this.value[index].amount * 100;

        list.push(
          Object.assign(this.value[index], {
            uniqueKey: this.generateUniqueKey(),
          })
        );
      }

      footTotals.amount = footTotals.amount / 100;
      this.footTotals = footTotals;

      this.$nextTick(() => (this.list = list));
    },
    handleAddClick() {
      this.$refs.invoiceItemForm.show();
    },
    handleItemSaved(item) {
      if (item.uniqueKey) {
        for (const index in this.list) {
          if (this.list[index].uniqueKey === item.uniqueKey) {
            this.list[index] = Object.assign({}, item);
            break;
          }
        }
      } else {
        this.list.push(
          Object.assign({}, item, { uniqueKey: this.generateUniqueKey() })
        );
      }
      this.$nextTick(() => this.$emit("input", this.list));
    },
    handleDeleteClick() {
      let message = `${this.selectedItems.length} tane fatura kalemini silmek istediğinizden emin misiniz?`;

      if (this.hasInstallments) {
        message = `${this.selectedItems.length} tane fatura kalemini silmek üzeresiniz. Fatura bedeli değişeceği için mevcut taksitler silinecek. Emin misiniz?`;
      }

      this.$refs.confirmDelete.show(message);
    },
    handleDeleteConfirmed() {
      if (this.selectedItems.length) {
        const selectedItems = this.selectedItems.map((item) => item.uniqueKey);
        this.list = this.list.filter(
          (item) => selectedItems.indexOf(item.uniqueKey) === -1
        );
        this.$refs.confirmDelete.hide();
        this.$nextTick(() => this.$emit("input", this.list));
      }
    },
    generateUniqueKey() {
      return (Math.random() * Math.pow(10, 10)).toFixed(0);
    },
    handleEditClick(item) {
      if (item.id) {
        this.$refs.invoiceItemForm.show(item);
      } else if (this.selectedItems.length === 1) {
        this.$refs.invoiceItemForm.show(this.selectedItems[0]);
      } else {
        this.$refs.invoiceItemForm.show(item);
      }
    },
    childExpenseTypeList(expenseTypeId) {
      if (!expenseTypeId) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(expenseTypeId);
    },
    getExpenseType(expenseTypeId) {
      for (const index in this.expenseTypeList) {
        if (this.expenseTypeList[index].id === parseInt(expenseTypeId)) {
          return this.expenseTypeList[index];
        }
      }

      return null;
    },
    getChildExpenseType(expenseTypeId, childExpenseTypeId) {
      const list = this.childExpenseTypeList(expenseTypeId);
      if (!list || !list.length) {
        return null;
      }

      for (const index in list) {
        if (list[index].id === parseInt(childExpenseTypeId)) {
          return list[index];
        }
      }

      return null;
    },
  },
};
</script>
